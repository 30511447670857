/**
 * Simple Plans Portal
 *
 * @author      Anoopkumar
 * @copyright   2023 Simple Plans.
 * @license     -
 *
 **/

.errorView {
  margin-left: 5px;
  margin-top: 3px;
  color: #f00;
  /* font-style: italic; */
  font-size: 12px;
  text-align: left;
}
