// .notification-message .message {
//   white-space: pre !important;
// }

// .MuiPaper-root {
//   border-bottom-right-radius: 0px !important;
//   border-bottom-left-radius: 0px !important;
// }

.formWrapper {
  overflow: hidden;
  flex: 1 1 100%;
}
.formContent {
  overflow: auto;
  flex: 1 1 100%;
  position: relative;
}
.form_view {
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  height: 100%;
  background-color: white;
}
.formSheetBg {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
  border-bottom: none;
  background-color: #f6f7fa;
  position: relative;
  padding-bottom: 50px;
}
.formStatusBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  border-bottom: 1px solid #c9ccd2;
  background-color: white;
  // margin-right: -16px;
}
.formStatusBarButtons {
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: flex;
  align-items: center;
  align-content: space-around;
}
.formStatusBarButtons2 {
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: flex;
  align-items: center;
  align-content: space-around;
  transform: scaleX(-1);
}

.formStatusBarButtons button {
  min-height: 25px;
  margin: 4px 3px 4px 0;
  padding-top: 2px;
  padding-bottom: 2px;
}
.formStatusBarStatusWidget {
  margin-left: auto;
  flex-flow: row-reverse wrap-reverse;
  align-self: stretch;
  align-items: stretch;
  margin-bottom: 0px;
  display: flex;
  align-content: space-around;
}
.transactionFormSheet {
  margin: 12px auto;
  background-color: white;
  border: 1px solid #c9ccd2;
  box-shadow: 0 5px 20px -15px #00000066;
  padding: 24px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-top: 0px;
}
.transactionFormGroup {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
}
.transactionTitle {
  max-width: 80%;
}
.transactionFormSecGroup {
  display: inline-table;
  width: 70%;
  vertical-align: top;
  margin: 0;
}
.transactionFormSecGroupGap {
  display: inline-table;
  width: 3%;
  vertical-align: top;
  margin: 0;
}
.transactionFormSecGroupRight {
  display: inline-table;
  width: 27%;
  vertical-align: top;
  margin: 0;
}
.transactionFormInnerGroup {
  display: inline-table;
  width: 50%;
  vertical-align: top;
  margin: 0;
}
.transactionFormInnerGroupGap {
  display: inline-table;
  width: 3%;
  vertical-align: top;
  margin: 0;
}
.transactionFormInnerGroupRight {
  display: inline-table;
  width: 47%;
  vertical-align: top;
  margin: 0;
}
table {
  border-collapse: collapse;
}
.td_label {
  width: 0%;
  padding: 0 15px 0 0;
  min-width: 150px;
  // display: inline-flex;
  // text-align: inherit;
}
.formLabel {
  font-weight: bold;
  min-height: 23px;
  margin-right: 0px;
  margin: 0 5px 0 0;
  font-weight: 500;
  line-height: 1.5;
  display: inline-block;
}
.td_input {
  width: 100%;
  padding: 0 45px 0 0;
  vertical-align: top;
}
.transactionFormInnerGroup .fieldWidget {
  width: 100%;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.transactionLogs {
  border-left-color: #c9ccd2;
  border-left: 1px solid;
  padding: 0;
  width: 20%;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  margin: 0 auto;
  max-width: 1140px;
}

.inv-ser-tilte {
  font-size: 28px;
  font-weight: 300;
  color: #137dbb !important;
}
.inv-tilte {
  font-size: 35px;
  font-weight: 400;
  width: 100%;
  color: #137dbb !important;
}
.abnTitle {
  font-size: 16px;
  color: #137dbb !important;
}
textarea.form-control {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: horizontal;
  border: inherit !important;
}
.th-form-control {
  height: 30px;
  border: inherit !important;
  box-shadow: inherit;
  padding: 6px 2px;
  background-image: none;
  border-radius: 4px;
  font-family: proxima_nova_rgregular, open sans, arial, FontAwesome;
  width: 100% !important;
  color: #fff !important;
}
.theadClass {
  font-size: 18px !important;
  color: #fff !important;
}
.btn-outline-save {
  border-color: #2e7d32;
  color: #2e7d32;
}
.invoice-manage-button {
  display: inherit;
}
.trns-entry-hash {
  text-align: center;
  width: 40px;
}
.trns-entry-product {
  width: 400px;
}
.trns-entry-quantity {
  width: 60px;
}
.trns-entry-menu,
.trns-entry-menu-twin {
  cursor: pointer;
  position: relative;
  text-align: center;
}
.trns-entry-menu {
  width: 40px;
}
.trns-entry-unit {
  width: 50px;
}
.trns-entry-label {
  width: 40px;
}
.trns-entry-price,
.trns-entry-subtotal {
  width: 80px;
}
.loading-button {
  align-items: center;
  display: inline-flex;
  font-size: 13px;
  margin-right: 10px;
  padding: 1px 10px;
}
.invoice-lines-table td,
.invoice-lines-table th {
  font-size: 1.03rem;
  padding: 0.3rem !important;
}
.no-border.line-count-select {
  border: 1px solid #c9ccd2 !important;
  border-radius: 6px;
  padding-left: 8px;
}
.line-count-select {
  height: 24px;
}
.line-count-select:before {
  border-bottom: 0px !important;
}
.line-count-select:after {
  border-bottom: 0px !important;
}
.line-count-arrow {
  vertical-align: middle;
}
.error-inv-line {
  background-color: #ffcccb !important;
}
.invoiceSummary {
  table-layout: fixed;
  width: 100%;
}
.invoiceAmountSummary {
  width: 100%;
}
.invoiceAmountSummaryTbl {
  width: 100%;
}
.invoiceAmountLabel {
  position: relative;
  padding-right: 10px;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
}
.invoiceAmountLabel:after {
  content: ":";
  position: absolute;
  right: 0;
}
.invoiceAmountValue,
.invoiceDiscountLabel {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.invoiceAmountValue {
  padding-left: 10px;
}
tr.space-between-rows {
  margin-bottom: 10px; /* Adjust the value as needed */
}
.autocomplete-option-text {
  white-space: normal; /* Allow text to wrap */
}

.new-info-icon {
  background-image: url("../img/new.png");
  background-repeat: no-repeat;
  background-position: center right 25px;
  padding-right: 10px;
}

.invoice-minimise-button {
  background-color: #8531a6 !important;
  padding: 1px !important;
  color: #fff !important;
  border-radius: 5px !important;
  margin-top: 7px !important;
  margin-right: 5px !important;
}
.invoice-maximise-button {
  background-color: #007acc !important;
  padding: 1px !important;
  color: #fff !important;
  border-radius: 5px !important;
  margin-right: 5px !important;
  margin-top: 7px !important;
}
.invoice-info-button {
  padding: 1px !important;
  color: #fc4438 !important;
  margin-right: 5px !important;
  margin-top: 7px !important;
}
.ui-settings-info-button {
  background-color: #2e7d32 !important;
  color: #fff !important;
  font-size: 0.7rem !important;
  border-radius: 4px !important;
  padding: 4px 6px !important;
  margin-left: 5px !important;
}
.dummy-chart {
  opacity: 0.2;
}
.reg-customers-info {
  position: absolute;
  top: 200px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  z-index: 999;
  color: #007acc;
}
.reg-customers-info-icon {
  font-size: 60px !important;
  color: #007acc;
}
.mail-count-badge {
  position: absolute;
  top: -19px;
  right: -8px;
  background-color: #007acc;
  color: #fff;
  padding: 0px 4px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: bold;
}
.add-line-button:focus {
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}
