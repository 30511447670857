.dashboardBG {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 100%;
  max-height: calc(100vh - 280px);
}

.tree-view-container {
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 11px;
  width: calc(100% - 22px);
  overflow: auto;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #bfbfc0;
  border-radius: 4px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: auto;
  max-height: 400px;
}

.tree-view-container .MuiTreeItem-label {
  width: auto !important;
}
.tree-view-container .MuiTreeItem-iconContainer {
  float: left;
}
.Mui-selected {
  background-color: #fff !important;
}
.MuiTreeItem-content:hover {
  background-color: #fff !important;
}
.acl-menu-select {
  background-color: none !important;
  width: 150px;
}
.acl-menu-select .MuiSelect-select {
  padding-left: 10px;
}
.subTreeACLTble .MuiFormControl-root {
  margin: 0px !important;
}
.menuLabel {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding-left: 5px;
}
.MuiTreeItem-iconContainer {
  width: auto !important;
}
.MuiTreeItem-iconContainer img {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}
.manage-menu-select {
  background-color: none !important;
  width: 60px;
}
.manage-menu-select .MuiSelect-select {
  padding-left: 10px;
}
.manage-menu-textfield {
  border-radius: 0px;
}
.manage-menu-textfield .MuiInput-root {
  padding-left: 10px;
}
.manage-menu-textfield .MuiInput-root::before {
  border-bottom: 0px !important;
}
.manage-menu-button {
  min-width: 0px !important;
  padding: 6px 12px !important;
}

.menuRow {
  height: 45px;
  position: relative !important;
  border: 1px solid #ccc;
  align-items: center;
}
.menuLabelContainer {
  align-items: center;
  display: flex;
  margin-left: 4px;
}
.menuInputContainer {
  height: 100%;
  float: right;
}
.MuiTreeItem-label {
  height: 100%;
}
.menuIconContainer {
  width: 50px;
  padding: 7px;
  border-left: 1px solid #ccc;
  margin-right: 5px;
  border-right: 1px solid #ccc;
}
.menuOrderContainer {
  width: 80px;
  height: 100%;
}
.menuTagContainer {
  width: 500px;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  border-left: 1px solid #ccc;
}

.MuiTreeItem-iconContainer {
  justify-content: left !important;
}

.menuOrderContainer .MuiInputBase-root {
  height: 100%;
}
.menuTagContainer .manage-menu-textfield {
  width: 100%;
  height: 100%;
}
.menuTagContainer .manage-menu-textfield .MuiInput-root {
  height: 100%;
}
.menuSeperatorContainer {
  width: 50px;
  border-left: 1px solid #ccc;
}

.menuIconContainer button {
  width: 100%;
  height: 100%;
}

.menuHead {
  width: 75%;
  margin: auto;
}

.menuContainerWrapper {
  background: #fff;
  padding-top: 10px;
  margin-bottom: 20px;
}
.menuAclContainer {
  width: auto;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid #ccc;
}

.ACLForm {
  padding-top: 20px;
}
.menuAclContainer .MuiInputBase-root {
  height: 100%;
}
.acl-menu-count {
  color: #ed6c02;
  font-weight: 600;
  font-size: 12px;
}

.icon-holder {
  background: #eee;
  display: block;
}

.img-fluid.pdficon {
  max-width: 32px !important;
}

.bankInfoQlContainer .ql-container {
  height: 100px !important;
}

.uploadedImageDiv {
  position: relative;
  width: 300px;
  margin-top: 10px;
}
.deleteButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #f00;
}
.uploadedImgDetails {
  margin-top: 10px;
}
