.bot-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-image: url("../img/fino.gif");
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  background-size: cover;
  user-select: none;
  opacity: 0.4;
  cursor: pointer;
}
.bot-container-wrapper {
  width: 100%;
  height: 100%;
}
.bot-container:hover {
  opacity: 1;
}
.bot-minimizer {
  position: fixed;
  bottom: 125px;
  right: 90px;
  width: 160px;
  padding: 0px;
  border-radius: 31px;
  box-shadow: 1px 4px 15px rgba(1, 1, 1, 0.35);
  z-index: 9999;
  border: 1px solid #fff;
}
.bot-needHelp-message {
  margin-top: 0px;
  margin-left: 30px;
  font-size: 13px;
  padding: 7px;
  color: #000;
}
.bot-needHelp-message::after {
  content: "Chat with Fino";
  display: block;
}
.bot-minimizeButton {
  position: absolute;
  top: -50px;
  left: 0px;
  border-radius: 20px;
  z-index: 9999999;
  color: #fff;
  padding: 1px;
}

.chat-container {
  overflow: hidden;
  box-sizing: border-box;
  height: 60%;
  border-radius: 6px;
  z-index: 9999;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 350px;
  -webkit-box-shadow: 0 4px 16px #00000040;
  box-shadow: 0 4px 16px #00000040;
}

.chat-header {
  display: flex;
  height: 48px;
}
.chat-headerInfo {
  cursor: move;
  color: #fff;
  width: 90%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.chat-minimizeButton {
  color: #fff;
  width: 10%;
  margin: auto;
  cursor: pointer;
}
.chat-body {
  display: flex;
  flex-direction: column;
  position: absolute;
  //max-height: 100%;
  //height: 100vh;
  width: 100%;
  overflow: hidden scroll;
    padding: 0 20px;
    background-color: #eaeef3;
    //height:  calc(100vh - 250px);
    bottom: 50px;
    top:48px;
}
.chat-main-body .rct-scroll {
  background-color: rgb(234, 238, 243);
}
.chat-item:last-child .chat-bubble-wrap {
  padding-bottom: 20px;
}
.chat-wrapper {
  flex-direction: column;
  position: relative;
  display: flex;
  flex-shrink: 0;
}

.chat-me {
  color: #fff;
  margin: 3px 1px 3px 20px;
  padding: 15px 17px;
  word-break: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 20px;
}
.chat-me-wrapper {
  align-items: flex-end;
}

.chat-bot {
  background-color: #fff;
  margin: 3px 20px 3px 1px;
  padding: 15px 17px;
  word-break: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 20px;
}
.chat-bot-wrapper {
  align-items: flex-start;
}

.chat-info {
}
.chat-time {
  width: 100%;
  text-align: center;
}
.chat-typingArea {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: auto;
}
.chat-textArea {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: rgb(0, 0, 0);
  border: none;
  padding: 15px 100px 15px 29px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;
  background-color: rgb(255, 255, 255);
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid rgb(230, 230, 230);
}
.chat-composerButtons {
  position: absolute;
  bottom: 28px;
  right: 21px;
  height: 100%;
  display: flex;
  align-items: center;
}
.chat-postIcon {
  cursor: pointer;
}
.chat-topLeftRadius {
  border-top-left-radius: 5px;
}
.chat-bottomLeftRadius {
  border-bottom-left-radius: 5px;
}

.chat-topRightRadius {
  border-top-right-radius: 5px;
}
.chat-bottomRightRadius {
  border-bottom-right-radius: 5px;
}
.chat-marginBottom {
  margin-bottom: 10px;
}
.chat-info-bot {
  font-size: 14px;
  color: rgb(24, 25, 25);
  display: flex;
  margin-bottom: 5px;
}
.chat-info-you {
  text-align: right;
  font-size: 14px;
  color: rgb(24, 25, 25);
}
.chat-botAvatar {
  padding: 3px;
  border-radius: 20px;
  margin-right: 5px;
}

.tag-container {
  position: absolute;
  bottom: 0px;
  right: 20px;
  height: 24px;
  border-radius: 5px 5px 0 0;
  padding: 4px 10px;
  font-size: 12px;
  z-index: 9999;
  cursor: pointer;
  color: #fff;
}
.tag-bot-eye {
  width: 14px;
  margin-top: -3px;
}
