.facebook-button-container span button {
  height: 40px;
  width: 100%;
  border-radius: 5px !important;
  padding: 2px;
}
.guest-signin-container {
  margin-bottom: 8px !important;
}
.google-places-autocomplete {
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.google-places-autocomplete:focus {
  border: 2px solid #6b9bf5;
}
.google-places-autocomplete::after {
  border-color: #000;
}

.user-login-info-msg {
  color: #007acc;
  font-style: italic;
}

.modulePageHeader {
  box-shadow: none;
  background-color: #fff;
  z-index: 9;
  padding: 1.25rem;
}
.manageAddressBookHeader {
  padding: 0.8rem;
}
.page-title-box .page-title-right {
  float: right;
}
