.remove-auto-capitalize {
  text-transform: none !important;
}

/*======== Horizontal Menu Layout ========*/
.hmenu-container {
  display: flex;
  background: $hoz-menu-bg;
  box-shadow: $hoz-box-shadow;
}
#left_menu_container {
  flex: 1;
}
#right_menu_container {
  flex: 0 0 60px;
}

.hmenu-main-nav,
.hmenu-drop-nav {
  // background: #2c3e50;
  background: $hoz-menu-bg;
}

.hmenu-main-nav {
  margin: 0px !important;
}
.hmenu-main-nav > li {
  float: left;
  list-style: none;
}

.hmenu-main-nav a {
  font-size: 13px;
  color: #000;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  width: max-content;
}
.hmenu-dropdown {
  position: relative;
  z-index: 999;
}
.hmenu-no-dropdown i {
  margin-right: 5px;
}
.hmenu-dropdown i {
  margin-right: 5px;
}
.hmenu-dropdown:after {
  content: "\25bc";
  font-size: 0.5em;
  display: block;
  position: absolute;
  top: 38%;
  right: 3%;
}
.hmenu-drop-nav {
  position: absolute;
  display: none;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09);
}

.hmenu-drop-nav li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  list-style: none;
  padding: 2px 10px 2px 0px;
  border-left: 4px solid #fff;
}

.hmenu-drop-nav li:hover {
  border-left: 4px solid #5d92f4;
}

.hmenu-dropdown:hover > .hmenu-drop-nav {
  display: block;
}

#right_menu_container {
  .hmenu-dropdown:after {
    display: none;
  }
  #right_menu_overflow_nav {
    right: -15px;
  }
  .hmenu-drop-nav-left {
    left: auto !important;
    right: 102%;
    top: 0;
  }
  .hToggleMenuIcon {
    height: 0.8em;
  }
  #right_menu_overflow_nav {
    li {
      padding: 0 10px 0 5px;
      border-left: 4px solid #fff;
    }
    li:hover {
      border-left: 4px solid #5d92f4;
    }
    .hmenu-dropdown:after {
      display: none;
    }
    .hmenu-dropdown:before {
      content: "\25c0";
      font-size: 0.5em;
      display: block;
      position: absolute;
      top: 38%;
      left: 4%;
    }
  }
}
