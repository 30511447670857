:root {
  --requisitionModalWidth: 85%;
}
.labelPadding-level-1 {
  padding-left: 3.625rem !important;
}
.labelPadding-level-2 {
  padding-left: 5.625rem !important;
}

html[dir="rtl"] .labelPadding-level-1 {
  padding-right: 3.625rem !important;
}
html[dir="rtl"] .labelPadding-level-2 {
  padding-right: 5.625rem !important;
}
.menuIconsList {
  cursor: pointer;
}
.menuIconsList:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}
.menuIcons {
  font-size: 24px;
}
.menuIconName {
  font-size: 11px;
}
.pl-5 {
  padding-left: 3rem !important;
}
.tableIconFont {
  font-size: 1.5rem;
}
.textCapitalize {
  text-transform: capitalize;
}
.textUpperCase {
  text-transform: uppercase;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 10000;
}
.loader {
  left: 50%;
  top: 30%;
  position: absolute;
}

.si_apps {
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  user-select: none;
}

.si_apps_menuitem {
  margin-left: 0;
  padding: 10px 0;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 3%;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  height: 100%;
  width: 33.33333333%;
}

.si_apps_icon {
  margin: auto;
  max-width: 70px;
  overflow: hidden;
  position: relative;
  width: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4%;
  box-shadow: 0 8px 0 -10px black;
  transition: all 0.3s ease 0s;
  display: block;
  height: 70px;
}

.si_apps_caption {
  margin: 10px 0;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.pulse:hover {
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

.fw-500 {
  font-weight: 500;
}

.moduleFooter {
  z-index: 999;
  position: fixed;
  height: 3.25rem;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  color: #fff;
}
.dashboard-module-header {
  height: 3.25rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background: #464d69;
  display: flex;
  transition: all 0.15s ease-in-out;
}
.dashboard-module-header .brand {
  height: 100%;
  width: 18.875rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.dashboard-module-header .brand .brand-logo {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-size: 0.91875rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
.dashboard-module-header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.dashboard-module-header .menu .menu-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.dashboard-module-header .dropdown {
  position: relative;
}
.dashboard-module-header .menu .menu-item .menu-link {
  text-decoration: none;
  color: #fff;
  position: relative;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}
.dashboard-module-header .menu .menu-item .menu-link .menu-icon {
  font-size: 1.25rem;
}
.dashboard-module-header .menu .menu-item .menu-link .menu-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}
.dashboard-module-header .menu .menu-item .menu-link .menu-img img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 30px;
}
.dashboard-module-header .dropdown-menu.show {
  -webkit-animation: appDropdownShow 0.25s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: appDropdownShow 0.25s both cubic-bezier(0.7, 0, 0.3, 1);
  display: block;
}
.dashboard-module-header .dropdown-menu {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
  transform: none;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0;
}
.dashboard-module-header .dropdown-menu-end {
  right: 0;
  left: auto;
  --bs-position: end;
}
.dashboard-module-header .dropdown-item {
  padding: 6px 20px;
}
.dashboard-module-header .ms-auto {
  margin-left: auto !important;
}
.dashboard-module-header .fs-16px {
  font-size: 16px !important;
}
.dashboard-module-header .w-300px {
  width: 300px !important;
}
.dashboard-module-header .py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.dashboard-module-header .dropdown-item {
  color: #464d69 !important;
}
.dashboard-module-header .text-wrap {
  white-space: normal !important;
}
.dashboard-module-header .fs-20px {
  font-size: 20px !important;
}
.dashboard-module-header .ps-3 {
  padding-left: 1rem !important;
}
.moduleClass {
  margin-top: 3.25rem;
  background-color: #464d69;
}
.footer-copyright {
  text-align: center;
}

@keyframes pulse {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 576px) {
  .si_apps_menuitem {
    width: 25%;
  }
}

@media only screen and (min-width: 768px) {
  .si_apps {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .si_apps_menuitem {
    width: 16.66666667%;
  }
}

@media only screen and (min-width: 992px) {
  .me-lg-3 {
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .dropdown.dropdown-mobile-full {
    position: initial;
  }
}

.MuiTable-root th,
.MuiTable-root td {
  padding: 6px;
}
/* add space for side bar menu icon for rtl */
.rtl .rct-sidebar .rct-sidebar-nav .rct-mainMenu li .menu-icon,
.mini-sidebar
  .rct-sidebar
  .rct-sidebar-nav
  .rct-mainMenu
  > li
  > a
  li
  .menu-icon {
  min-width: auto;
  margin-right: 0rem;
  margin-left: 1rem;
}
/* checkbox issue when switch languages */
.rtl
  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.PrivateSwitchBase-root.Mui-checked {
  width: 38px;
  height: 38px;
}
.rtl
  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root {
  width: 38px;
  height: 38px;
}
.MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root {
  width: 38px;
  height: 38px;
}
/* Datatable issues */
.dataTableCustomCell {
  color: rgba(0, 0, 0, 0.87);
}
div.MuiPopover-root.MuiModal-root[role="presentation"] {
  .MuiFormControl-root {
    padding: 16px 24px 16px 24px;
    font-family: Roboto;
  }
  .MuiTypography-root.MuiTypography-caption {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-weight: 500;
    margin-left: -7px;
  }
  .MuiList-root[role="listbox"] li.MuiMenuItem-root {
    white-space: initial;
  }
}
.MuiTablePagination-toolbar {
  .MuiTablePagination-selectLabel {
    margin-bottom: 0;
  }
  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }
}

.tss-1opebqo-MUIDataTable-caption {
  display: none;
}
/* hide today button from calender */
// .rbc-btn-group button:first-child {
//   display: none;
// }
/* horizontal menu user dropdown issue */
.horizontal-menu
  .sidebar-user-block
  .rct-dropdown
  .dropdown-menu
  ul
  li:not(.user-profile-top) {
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
  margin: 0.3125rem;
}
.horizontal-menu
  .sidebar-user-block
  .rct-dropdown
  .dropdown-menu
  ul
  li:not(.user-profile-top)
  a {
  background-color: #fff;
  padding: 0.75rem 1rem;
  display: block;
}
.horizontal-menu
  .sidebar-user-block
  .rct-dropdown
  .dropdown-menu
  ul
  li:not(.user-profile-top)
  a
  span:not(.badge) {
  color: #464d69;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}
.horizontal-menu
  .sidebar-user-block
  .rct-dropdown
  .dropdown-menu
  ul
  li:not(.user-profile-top)
  a {
  background-color: #fff;
  padding: 0.75rem 1rem;
  display: block;
}
.horizontal-menu
  .sidebar-user-block
  .rct-dropdown
  .dropdown-menu
  ul
  li:not(.user-profile-top)
  a:hover
  span:not(.badge) {
  color: #5d92f4;
}
/* mini sidebar design issue */
.mini-sidebar .rct-header {
  left: 0;
}
.customModal.modal-dialog {
  position: absolute;
  // margin: auto;
  width: 1050px;
  min-height: 100vh;
  height: auto;
  max-height: auto;
  right: 0px;
  left: auto;
  top: 0px;
  margin-top: 0;
}
.customModal.customModalWithLargeWidth {
  width: var(--requisitionModalWidth) !important;
  max-width: var(--requisitionModalWidth) !important;
}
.customModal.requisitionModal {
  max-height: 100vh;
}
// .customModal.requisitionModal .card-body {
//   max-height: 85vh;
//   padding: 0 12px;
// }
.customModal.requisitionModal .MuiTabs-vertical {
  max-height: 85vh;
  min-width: 20vw;
}
.customModal.requisitionModal .MuiTabs-vertical .Mui-disabled {
  opacity: 0.3;
}
.customModal.requisitionModal .modal-body {
  padding: 0;
}
.customModal.requisitionModal .purReqTab .card {
  border: none !important;
  box-shadow: none;
}
.customModal.requisitionModal .requisitionTabs {
  border: none !important;
  border-bottom: 1px solid #eee !important;
  // box-shadow: 0 3px 2px 0 rgba(0, 0, 0, .2);
}
.customModalWithMediumWidth {
  width: 50% !important;
}
.customModalWithSmallWidth {
  width: 40% !important;
}
.requisitionSidebar .sidebarSearchReq {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.requisitionSidebar .sidebarSearchReq svg {
  opacity: 0.6;
}
.requisitionSidebar .searchInput {
  border: none !important;
}
.requisitionSidebar .searchInput input {
  padding: 0.5rem;
}
.requisitionSidebar .searchInput fieldset {
  border: none;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 75%;
  }
}
.rtl .customModal.modal-dialog {
  left: 0px;
  right: auto;
}

.customModal .modal-content {
  min-height: 100vh;
  height: auto;
  max-height: auto;
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
}

.rightBottomModal {
  width: 30%;
  position: fixed;
  bottom: 0;
  right: 25px;
  margin: 0;
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  // min-height: 100vh;
  height: 100%;
  margin: 0;
}
.intlTelephoneInputStandard {
  border: 0 !important;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0 !important;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100% !important;
}
.intlTelephoneInputStandard:focus {
  color: #464d69;
  background-color: #fff;
  border-color: none;
  outline: 0;
  border-bottom: 2px solid #00d0bd;
  box-shadow: none;
}
.form-input-error {
  border-bottom-color: #d32f2f !important;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
/************ Google Places **********************/
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.ql-editor span {
  display: inline-block;
  min-width: 1px;
}

.pac-container.bottom {
  // top: 24px;
}

.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #999;
}

.pac-item:hover {
  background-color: #fafafa;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}

.pac-matched {
  font-weight: 700;
}

.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url("../img/autocomplete-icons.png");
  background-size: 34px;
}

.hdpi .pac-icon {
  background-image: url("../img/autocomplete-icons_hdpi.png");
}

.pac-icon-search {
  background-position: -1px -1px;
}

.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}

.pac-placeholder {
  color: gray;
}

@media (min-width: 576px) {
  .vertically-center-modal {
    min-height: calc(100% - (1.75rem * 2));
  }
}

.vertically-center-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 70% !important;
  max-width: 700px !important;
}
.companyDropDown {
  .MuiInputBase-formControl {
    color: #fff;
  }
  .MuiInputBase-formControl {
    color: #fff;
  }
  .MuiInputBase-formControl.Mui-focused {
    background: #464d69 !important;
    box-sizing: inherit;
  }
  .MuiInputBase-formControl:before,
  .MuiInputBase-formControl:after {
    border: none;
  }
  .MuiInputBase-formControl:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #fff;
  }
  .MuiSvgIcon-root {
    color: #fff;
  }
}
.MuiAutocomplete-inputRoot {
  padding-left: 0;
}
MuiAutocomplete-endAdornment {
  left: 0;
}
.MuiNativeSelect-iconStandard {
  right: 0;
}
.rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  left: 0;
}
.react-tel-input .form-control {
  padding-right: auto;
  height: 100% !important;
}
.rtl .react-tel-input .form-control {
  padding-right: 48px;
  padding-left: auto;
}
.react-tel-input .selected-flag .flag {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.react-tel-input .selected-flag .arrow {
  left: 20px;
  right: auto;
}
.react-tel-input-custom {
  height: 100%;
}
.rtl .react-tel-input .selected-flag .arrow {
  right: 20px;
  left: auto;
}
.react-tel-input {
  height: 100%;
}
.contactBlockCtrl {
  height: 100% !important;
}
.contactBlockCtrl {
  .MuiFormControl-root,
  .MuiInput-root {
    height: 100% !important;
  }
  input {
    height: 100% !important;
  }
}

.funkyText {
  color: mix(#fff, #037bd5, 80%);
  font-size: calc(1em + 20vw);
  font-weight: 900;
  text-shadow: -0.0075em 0.0075em 0 mix(#fff, #037bd5, 94%),
    0.005em 0.005em 0 mix(#fff, #037bd5, 60%),
    0.01em 0.01em 0 mix(#fff, #037bd5, 62%),
    0.015em 0.015em mix(#fff, #037bd5, 64%),
    0.02em 0.02em 0 mix(#fff, #037bd5, 66%),
    0.025em 0.025em 0 mix(#fff, #037bd5, 68%),
    0.03em 0.03em 0 mix(#fff, #037bd5, 70%),
    0.035em 0.035em 0 mix(#fff, #037bd5, 72%);
}

.MuiTableCell-paddingCheckbox .MuiCheckbox-root {
  padding-top: 0;
  padding-bottom: 0;
}
.companySection {
  color: #f5f2f2;
  font-size: 17px;
}
.rct-sidebar .site-logo {
  display: block;
}
.rct-sidebar .site-logo .companySection {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #f5f2f2;
}
@media (min-width: 600px) {
  .app-header .MuiToolbar-root {
    min-height: 70px;
  }
}
.app-header .rct-header .site-logo {
  padding: 0 4rem 0 2rem;
}
.app-horizontal .app-header .MuiToolbar-root {
  min-height: 68px;
}
.rct-sidebar .site-logo {
  padding: 0.3rem 1.5rem;
}
.no-data-image-container {
  text-align: center;
}
.no-data-image-container .no-data-image {
  width: 500px;
  height: 500px;
}
.app-horizontal .app-header .humburger {
  display: inline-flex;
}
.app-horizontal .app-header .navbar-left li:first-child .humburger {
  display: none;
}
.app-horizontal .rct-page .rct-scroll {
  height: calc(100vh - 120px) !important;
}
.app-horizontal .moduleClass .rct-scroll {
  height: calc(100vh - 50px) !important;
}

.pointerCursor {
  cursor: pointer;
}

.search-data-list {
  position: absolute;
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}
.search-data-item {
  color: #464d69;
  padding: 5px 10px;
  border-bottom: 1px solid #f5f2f2;
  cursor: pointer;
  font-size: 13px;
}
.search-data-no-item {
  color: #999;
  padding: 5px 10px;
  border-bottom: 1px solid #f5f2f2;
  font-style: italic;
  font-size: 13px;
}
.search-data-item:hover {
  background-color: #f5f2f2;
}
.search-progress-icon {
  position: absolute;
  right: 7px;
  top: 9px;
}
.img-upload-container {
  height: 150px;
}
.img-upload-container .img-fluid {
  height: 150px;
  width: 100%;
}
.checkBoxGroupWithAutoWidth {
  display: inline-block !important;
}

.customTabColor.MuiBox-root {
  background-color: azure;
}
.app-main-container div[role="navigation"] {
  overflow: inherit !important;
}
.tableSortIcon {
  float: right;
}
.muiDataTableCustom .MuiButtonBase-root.MuiChip-root.MuiChip-filled {
  //Mui datatable filter design
  margin-bottom: 10px;
  margin-right: 5px;
}
/****************Style For Datatable Printing*************/
.printSection {
  h4 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  .countCell {
    width: 50px;
  }
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
/****************Kanban Pagination**************/
.kanbanContent {
  padding-top: 10px;
}
.kanbanContent,
.kanbanPagination {
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dummyKanbanFooter {
  height: 50px;
}
.kanbanZeroRecord {
  width: 100%;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
}
// .kanbanPagination {
//   .pager {
//     padding-left: 0;
//     margin: 20px 0;
//     text-align: center;
//     list-style: none;
//     float: right;
//     margin-right: 20px;
//   }
//   .pager:before {
//     display: table;
//     content: " ";
//   }
//   .pager li {
//     display: inline;
//     margin-right: 10px;
//   }
//   .pager li > a,
//   .pager li > span {
//     display: inline-block;
//     padding: 5px 14px;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     border-radius: 15px;
//   }
//   .pager:after {
//     clear: both;
//   }
//   .pager .disabled {
//     pointer-events: none;
//     cursor: default;
//   }
//   .pager .disabled a {
//     color: rgba(0, 0, 0, 0.26);
//   }
//   .rowsPerPageSelect fieldset {
//     border-color: transparent;
//   }
//   .rowsPerPageSelect.Mui-focused fieldset {
//     border-color: transparent !important;
//     border-width: 0px !important;
//   }
//   .rowsPerPageSelect:hover fieldset {
//     border-color: transparent !important;
//   }
//   .rowsPerPageSelect #rowsPerPageSelect {
//     padding-top: 4px;
//     padding-bottom: 4px;
//   }
// }

// Menu Seperator For Manin Menu
.menu-seperator {
  border-top: 1px solid #f0f0f0;
}

hr.style-two {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

// Maintenance Loader Styles
.maintenance-loader {
  height: 100%;
  text-align: center;
  display: flex;
  background-color: #fff;
  flex-direction: column;
}
.maintenance-image {
  max-width: 80%;
  max-height: 80%;
}
.maintenance-info {
  h2 {
    color: #518dc1;
  }
  h5 {
    color: #456d8f;
  }
}
.createNewLink {
  cursor: pointer;
  color: #037bd5;
  text-decoration: underline;
  font-size: 15px;
}

/********Book selection in header***********/
.book-breadcrumb-container {
  display: flex !important;
  justify-content: flex-end !important;
  flex-grow: 100 !important;
}
.bookSelectionCombo {
  margin-right: 10px !important;
}
.bookSelectionInput {
  margin-top: 6px !important;
}
.fakeBookDIV {
  border: 1px saddlebrown solid;
  visibility: hidden;
}
.fa-book-icon {
  margin-top: 9px;
  margin-right: 10px;
}
.jodit-dialog__content td:empty {
  display: none;
}
.jodit-status-bar .jodit-status-bar-link {
  display: none;
}
#data-table .table {
  margin-bottom: 0px !important;
}

.horizontal-menu .dropdown .dropdown-menu {
  transform: translate3d(-100px, 50px, 0px) !important;
}

.userProfileList li:not(.user-profile-top) a:hover span:not(.badge),
.userProfileList li:not(.user-profile-top) a:hover i {
  color: #fff !important;
}

// To Remove MUI Date Range Picker Liscense Error
div[role="tooltip"]
  .MuiPickersPopper-paper
  > div:first-child
  > div:first-child {
  display: none;
}
//----------------------------------------------
.readOnlySelectInput:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.readOnlySelectInput:disabled {
  color: #000 !important;
}

.invoice_form_container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Horizontally centers content */
  align-items: center;
}

.dark-mode .hmenu-container,
.dark-mode .hmenu-container .hmenu-main-nav,
.dark-mode .hmenu-container .hmenu-drop-nav {
  background-color: #2c3644 !important;
}

body.dark-mode {
  color: #fff;
}

.dark-mode .formStatusBar,
.dark-mode .transactionFormSheet,
.dark-mode .formSheetBg {
  background-color: #2c3644;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
}

body.theme-primary .humburger {
  color: #fff !important;
}

.btn-outline-confirm {
  color: #0288d1;
  background-color: transparent;
  background-image: none;
  border-color: #0288d1;
}
.btn-outline-discard {
  color: #ed6c02;
  background-color: transparent;
  background-image: none;
  border-color: #ed6c02;
}
.btn-outline-reject {
  color: #d32f2f;
  background-color: transparent;
  background-image: none;
  border-color: #d32f2f;
}
//Date Picker Styles For Standard, Outlined MUI Textboxes
.customDatePickerMui {
  width: 100%;
}
